export const GOOGLE_API_KEY = "AIzaSyBe6vrykAsiKjwIHWOUcfH1Li4VT-2LHTg";

// export const REST_API_END_POINT = "http://localhost:5000/webservice/";
// export const REST_API = "http://localhost:5000/";
// export const BASE_URL = "http://localhost:3000/"; 


// export const REST_API_END_POINT = "http://batman-api.skyniche.website/webservice/";
// export const REST_API = "http://batman-api.skyniche.website/"; 
// export const BASE_URL = "http://batman.skyniche.website/";

export const REST_API_END_POINT = "https://bagempirela.com/backend/webservice/";
export const REST_API = "https://bagempirela.com/backend/";
export const BASE_URL = "https://bagempirela.com/";

export const LBS_CONSTANT= 453.59237;
export const Build = '1.86';
export const Features = [
  //  { id: 1, label: `Tier pricing changed. New variations - Platinum,Silver,Gold` },
   { id: 1, label: `Return products from receive Inventory` },
   { id: 1, label: `Profile and Loss Report` },
]
export const BugFixes = [
  { id: 1, label: `Receive Inventory bug fixes` },
  { id: 1, label: `POS Bug Fix ` },
  // { id: 2, label: `Tier pricing updation bugfixes ` },
  // { id: 2, label: `POS bugfixes ` },
  // { id: 3, label: `Live Inventory Bug FIx ` },
  // { id: 4, label: `Shop Bug FIx ` },
  // { id: 1, label: `Customer Points bug fixes` },
  // { id: 2, label:  bug fixes` },
  // { id: 3, label: `Coupon bug fixes` },
  // { id: 4, label: `Invoice and refund bug fixes` },
]

export const OUNCE_CONSTANT = 28;
export const FACILITY_ID = 7;
export const INVENTORY_SOURCES = { '0': 'Inventory', '1': 'Grow House', '2': 'Vendor', '3': 'Extractor', '4': 'Rotovap', '5': 'Distillator', '6': 'Kitchen', '7': 'Oven', '8': 'MCP' };


