// import * as Yup from 'yup';
// import PropTypes from 'prop-types';
// import { useSnackbar } from 'notistack';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useCallback, useEffect, useRef, useState } from 'react';
// import { Form, FormikProvider, useFormik } from 'formik';
// // material
// import { experimentalStyled as styled } from '@material-ui/core/styles';
// import { Grid, Stack, Typography, Box, Button } from '@material-ui/core';

// import useAuth from 'src/hooks/useAuth';
// import axios from 'axios';
// import moment from 'moment';

// // ----------------------------------------------------------------------

// const LabelStyle = styled(Typography)(({ theme }) => ({
//   ...theme.typography.subtitle2,
//   color: theme.palette.text.secondary,
//   marginBottom: theme.spacing(1),
// }));

// // Main Component
// export default function PrintOrderLabels({
//   setOpenPrint,
//   currencies,
//   invoiceOrderData,
//   facilityData,
//   wholeOrderData,
//   orderProduct,
//   orders,
//   handleOpenInvoice,
//   paymentStatus,
// }) {
//   const { enqueueSnackbar } = useSnackbar();
//   const { user } = useAuth();
//   const iframeRef = useRef(null);

//   const filteredOrders = orders.filter(order => order.order_id === wholeOrderData.order_id);

//   const generateLabelHTML = (content) => {
//     return `
//       <html>
//         <head>
//           <title>Print</title>
//           <style>
//             body { font-family: Arial, sans-serif; margin: 20px; }
//             h1 { font-size: 24px; }
//             h2, h3 { font-size: 18px; }
//             p { font-size: 16px; }
//             .order-number { text-align: right; font-weight: bold; margin-bottom: 20px; }
//           </style>
//         </head>
//         <body>
//           ${content}
//         </body>
//       </html>
//     `;
//   };

//   // Print functionality
//   const handlePrint = (content) => {
//     const iframe = iframeRef.current;
//     const doc = iframe.contentWindow.document;
//     const html = generateLabelHTML(content);

//     // Write content to iframe and print
//     doc.open();
//     doc.write(html);
//     doc.close();
//     iframe.contentWindow.focus();
//     iframe.contentWindow.print();
//   };

//   const printCustomer = () => {
//     let content = '';

//     orderProduct.forEach((product) => {
//       // let  taxAmount =(product.product_unit_price * 5 /100 * product.product_qty) || 0
//       let  taxAmount =product.product_sales_tax || 0
//       content += `
//         <div style="page-break-after: always;">
//           <div class="order-number">Order Number: ${invoiceOrderData.order_number}</div>
//           <h1 style="font-weight: bold;">This product contains THC</h1>
//           <h4>Customer: ${invoiceOrderData.customer_name}</h4>
//           <h1 style="font-weight: bold;">${product.product_name}</h1>
//           <h3>Store: ${user?.facility_name || 'Get Go'}</h3>
//           <h4>${product.variation_name ? 'Weight : '+product.variation_name :'' }</h4>
//           <h4>Order Date: ${moment.unix(wholeOrderData.timestamp).format('MM/DD/YYYY')}</h4>
//           <h3>Price:$ ${product.product_unit_price * product.product_qty + taxAmount * product.product_qty}</h3>
//         </div>`;
//     });
//     handlePrint(content);
//   };

//   const printProduct = () => {
//     let  content=``
    
//     orderProduct.forEach((product) => {
//       // let  taxAmount =(product.product_unit_price * 5 /100 * product.product_qty) || 0
//       let  taxAmount =product.product_sales_tax || 0


//   content += `
//       <div style="text-align: center; padding: 40px;">
//         <h2 style="font-weight: 400; font-size: 22px;  text-decoration: underline;">${user?.facility_name || 'Get Go'}</h2>
 
//           </div>
//         <h1 style="font-weight: bold; font-size: 24px; margin: 20px 0;">${product.product_name}</h1>
//         <p style="font-size: 18px;">${filteredOrders[0].category_name ? 'Category : '+filteredOrders[0].category_name : '' }</p>
//         <h3>Price:$ ${product.product_unit_price * product.product_qty + taxAmount * product.product_qty}</h3>
  
//         <!-- Barcode -->
//         <div style="margin-top: 20px;">
//         <img src="https://barcode.tec-it.com/barcode.ashx?data=${filteredOrders[0].barcode}&type=EAN13&size=Medium" alt="barcode" />
  
//       </div>
     
//     `;
//   });

//     handlePrint(content);
//   };
  

//   const printReceipt = () => {
//     // const content = `<h1>Receipt</h1><p>Details about the receipt...</p>`;
//     // handlePrint(content);
//     handleOpenInvoice()
//   };

//   const emailReceipt = () => {
//     enqueueSnackbar('Email Receipt sent successfully!', { variant: 'success' });
//     // Add your email sending logic here
//   };

//   const textReceipt = () => {
//     enqueueSnackbar('Text Receipt sent successfully!', { variant: 'success' });
//     // Add your text messaging logic here
//   };

//   return (
//     <Grid container spacing={3}>
//       <Grid item xs={12} md={12}>
//         <Stack spacing={3}>
//           {/* First row of buttons */}
//           <Stack
//             direction={{ xs: 'column', sm: 'row' }}
//             spacing={{ xs: 3, sm: 2 }}
//             sx={{ justifyContent: 'space-evenly' }}
//           >
//             <Button type="button" variant="contained" onClick={printCustomer}>
//               Print Customer Label
//             </Button>
//             <Button type="button" variant="contained" onClick={printProduct}>
//               Print Product Label
//             </Button>
//             { paymentStatus ==='Paid' && (
//             <Button type="button" variant="contained" onClick={printReceipt}>
//               Print Receipt
//             </Button>
//             )}
//           </Stack>

//           {/* Second row of buttons */}
//           <Stack
//             direction={{ xs: 'column', sm: 'row' }}
//             spacing={{ xs: 3, sm: 2 }}
//             sx={{ justifyContent: 'center' }}
//           >
//             {/* <Button type="button" variant="contained" onClick={emailReceipt}>
//               Email Receipt
//             </Button>
//             <Button type="button" variant="contained" onClick={textReceipt}>
//               Text Receipt
//             </Button> */}
//           </Stack>

//           {/* Cancel Button */}
//           <Box sx={{ display: 'flex', justifyContent: 'center' }}>
//             <Button
//               type="button"
//               variant="outlined"
//               color="inherit"
//               onClick={() => {
//                 setOpenPrint(false);
//               }}
//             >
//               Cancel
//             </Button>
//           </Box>
//         </Stack>
//       </Grid>

//       {/* Hidden iframe for printing */}
//       <iframe
//         ref={iframeRef}
//         title="print-frame"
//         style={{ display: 'none' }}
//       />
//     </Grid>
//   );
// }
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Grid, Stack, Typography, Box, Button } from '@material-ui/core';

import useAuth from 'src/hooks/useAuth';
import axios from 'axios';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import Barcode from 'react-barcode';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// Customer Label Component
const CustomerLabel = React.forwardRef(({ orderProduct, invoiceOrderData, user, wholeOrderData }, ref) => (
  <div ref={ref} style={{ display: 'flex', flexDirection: 'column' }}>
    {orderProduct.map((product, index) => (
      <div 
        key={product.product_name} 
        style={{ marginBottom: '20px', marginLeft: '15px' }}
      >
        <div className="order-number" style={{ textAlign: 'right', marginTop: '20px', marginRight: '20px', fontWeight: 'bold' }}>
          Order Number: {invoiceOrderData.order_number}
        </div>
        <h1>This product contains THC</h1>
        <h4>Customer: {invoiceOrderData.customer_name}</h4>
        <h1>{product.product_name}</h1>
        <h3>Store: {user?.facility_name || 'Get Go'}</h3>
        <h4>{product.variation_name ? `Weight: ${product.variation_name}` : ''}</h4>
        <h4>Order Date: {moment.unix(wholeOrderData.timestamp).format('MM/DD/YYYY')}</h4>
        <h3>Price: ${product.product_unit_price * product.product_qty + (product.product_sales_tax || 0) * product.product_qty}</h3>
      </div>
    ))}
  </div>
));


// Product Label Component
const ProductLabel = React.forwardRef(({ orderProduct, filteredOrders, user }, ref) => (
  <div ref={ref}>
    {orderProduct.map((product) => (
      <div key={product.product_name} style={{ textAlign: 'center', padding: '40px' }}>
        <h2 style={{ fontSize: '22px', textDecoration: 'underline' }}>
          {user?.facility_name || 'Get Go'}
        </h2>
        <h1 style={{ fontWeight: 'bold', fontSize: '24px', margin: '20px 0' }}>{product.product_name}</h1>
        <p style={{ fontSize: '18px' }}>
          {filteredOrders[0]?.category_name ? `Category: ${filteredOrders[0].category_name}` : ''}
        </p>
        <h3>Price: ${product.product_unit_price * product.product_qty + (product.product_sales_tax || 0) * product.product_qty}</h3>
        <div style={{ marginTop: '20px' }}>
          <Barcode value={product?.barcode || '000000000'} height={40} />
          {/* <Barcode value={filteredOrders[0]?.barcode || '000000000'} height={40} /> */}
        </div>
      </div>
    ))}
  </div>
));

// Main Component
export default function PrintOrderLabels({
  setOpenPrint,
  invoiceOrderData,
  wholeOrderData,
  orderProduct,
  orders,
  handleOpenInvoice,
  paymentStatus,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const customerLabelRef = useRef(null);
  const productLabelRef = useRef(null);

  const filteredOrders = orders.filter(order => order.order_id === wholeOrderData.order_id);

  const emailReceipt = () => {
    enqueueSnackbar('Email Receipt sent successfully!', { variant: 'success' });
  };

  const textReceipt = () => {
    enqueueSnackbar('Text Receipt sent successfully!', { variant: 'success' });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Stack spacing={3}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 3, sm: 2 }}
            sx={{ justifyContent: 'space-evenly' }}
          >
            {/* Customer Label Print Button */}
            <ReactToPrint
              trigger={() => (
                <Button type="button" variant="contained">
                  Print Customer Label
                </Button>
              )}
              content={() => customerLabelRef.current}
            />

            {/* Product Label Print Button */}
            <ReactToPrint
              trigger={() => (
                <Button type="button" variant="contained">
                  Print Product Label
                </Button>
              )}
              content={() => productLabelRef.current}
            />

            {paymentStatus === 'Paid' && (
              <Button type="button" variant="contained" onClick={handleOpenInvoice}>
                Print Receipt
              </Button>
            )}
          </Stack>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              variant="outlined"
              color="inherit"
              onClick={() => setOpenPrint(false)}
            >
              Cancel
            </Button>
          </Box>
        </Stack>
      </Grid>

      {/* Hidden Components for Printing */}
      <div style={{ display: 'none' }}>
        <CustomerLabel
          ref={customerLabelRef}
          orderProduct={orderProduct}
          invoiceOrderData={invoiceOrderData}
          user={user}
          wholeOrderData={wholeOrderData}
        />
        <ProductLabel
          ref={productLabelRef}
          orderProduct={orderProduct}
          filteredOrders={filteredOrders}
          user={user}
        />
      </div>
    </Grid>
  );
}
