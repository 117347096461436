import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
// material
import { experimentalStyled as styled } from '@material-ui/core/styles'
import { LoadingButton } from '@material-ui/lab'
import {
  Card,
  Chip,
  Grid,
  Stack,
  Radio,
  Switch,
  Select,
  TextField,
  InputLabel,
  Typography,
  RadioGroup,
  FormControl,
  Autocomplete,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
  Box,
  Button,
  DialogActions,
  Checkbox,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Badge,
  Paper,
} from '@material-ui/core'

import {
  UploadAvatar,
  UploadMultiFile,
  UploadSingleFile,
} from 'src/components/upload'
import useAuth from 'src/hooks/useAuth'
import { OUNCE_CONSTANT, REST_API_END_POINT } from 'src/constants/DefaultValues'
import axios from 'axios'
import { empty, zeroPad } from 'src/constants/Helper'
import { QuillEditor } from 'src/components/editor'
import moment from 'moment'
import { CUSTOMER_ORDER_STATUS } from './allOrders'
import Label from 'src/components/Label'
import { Cancel } from '@material-ui/icons'
//

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}))

// ----------------------------------------------------------------------

const getData = (source, ids) => {
  console.log('source', source)
  console.log('ids', ids)
  if (!ids) return []
  return ids
    .split(',')
    .map((id) => source.find((item) => item.id == id))
    .filter((item) => item !== undefined)
}

export default function ViewOrderDetails({
  order_details,
  setOpenview,
  fetchData,
  fromEnquiry,
}) {
  const [message, setMessage] = useState('')

  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const { user } = useAuth()
  const NewProductSchema = Yup.object().shape({})

  const cancelOrder = () => {
    axios
      .post(`${REST_API_END_POINT}point-of-sale/cancel-order`, {
        user_id: user.id,
        facility_id: user.facility_id,
        order_ids: [order_details.order_id],
      })
      .then((res) => {
        if (res.data.status === 1) {
          fetchData()
          setOpenview(false)
          enqueueSnackbar(res.data.message, { variant: 'success' })
        }
      })
      .catch((err) => {
        console.log(err)
        setOpenview(false)
        enqueueSnackbar(err, { variant: 'error' })
      })
  }
  const postReply = () => {
    if (message !== '') {
      // axios.post(`${REST_API_END_POINT}`)
    }
  }
  const order_status = CUSTOMER_ORDER_STATUS.find((r) => {
    return r.value == order_details.status
  })
  let status_text = ''
  let status_badge = ''
  if (order_status != null) {
    status_text = order_status.label
    status_badge = (
      <Badge
      //  color={order_status.badge}
      >
        {order_status.label}
      </Badge>
    )
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Paper sx={{ p: 1 }}>
          <Stack spacing={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 2, borderRadius: '6px' }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar
                      src={order_details?.client_avatar}
                      alt={order_details.vendor_name}
                      sx={{ width: 50, height: 50 }}
                    />
                    <Stack direction="column" spacing={0.5}>
                      <Typography variant="subtitle2">
                        Patient Name: {order_details.vendor_name}
                      </Typography>
                      {!empty(order_details.customer_email) &&
                      <Typography variant="body2">
                        Email ID: {order_details.customer_email}
                      </Typography>}
                      {!empty(order_details.customer_tg) &&
                      <Typography variant="body2">
                        Telegram: {order_details.customer_tg}
                      </Typography>
                      }
                      {!empty(order_details.customer_signal) &&
                      <Typography variant="body2">
                        Signal: {order_details.customer_signal}
                      </Typography>
                      }
                      {!empty(order_details.customer_phone) &&
                      <Typography variant="body2">
                        Phone: {order_details.customer_phone}
                      </Typography>
                      }
                      <Typography variant="body2">
                        Created:{' '}
                        {moment(order_details.timestamp * 1000).format(
                          'MM/DD/YYYY HH:mm',
                        )}
                      </Typography>
                      {!fromEnquiry && (
                      <Typography variant="body2">
                        Status:{' '}
                        <Label color="info" variant="filled">
                          {status_badge}
                        </Label>
                      </Typography>
                      )}
                      {order_details.payment_mode != 0 && !fromEnquiry &&
                        order_details.payment_mode != '' && (
                          <Typography variant="body2">
                            Payment Mode: {order_details?.payment_status !== 0 && order_details.payment_mode}
                            {order_details?.payment_status === 2 && (
                              <Label
                                sx={{ ml: 1 }}
                                color="warning"
                                variant="ghost"
                              >
                                {'Partially Paid'}
                              </Label>
                            )}
                            {order_details?.payment_status === 0 && (
                              <Label
                                sx={{ ml: 1 }}
                                color="error"
                                variant="ghost"
                              >
                                {'Unpaid'}
                              </Label>
                            )}
                          </Typography>
                        )}
                      {order_details.status == 1 &&
                        order_details.ap_username != null && (
                          <Typography variant="body2">
                            Approved By: {order_details.ap_username}
                          </Typography>
                        )}
                    </Stack>
                  </Stack>
                </Card>
                {order_details?.status != 3 && !fromEnquiry &&
                order_details?.status != 4 &&
                order_details?.status != 5 ? (
                  <Box sx={{ mt: 2.5 }}>
                    <Button
                      disabled={
                        !user?.permissions?.some((permission) =>
                          ['Cancel Order'].includes(permission),
                        )
                      }
                      onClick={cancelOrder}
                      variant="outlined"
                      color="error"
                      size="small"
                      startIcon={<Cancel />}
                      sx={{ borderRadius: '30px' }}
                    >
                      Cancel Order
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="h6" sx={{ mb: 3, mt: 2 }}>
                  Order Number: #{zeroPad(order_details.order_number, 6)}
                </Typography>

                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TableContainer sx={{ minWidth: 320 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Product</TableCell>
                          <TableCell>Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {order_details.order_products?.map((row, index) => {
                          const formattedProductQty =
                            row.is_finished === 0
                              ? `${parseFloat(row.product_qty)} `
                              : parseInt(row.product_qty, 10)

                          const variationName =
                            row.variation_name != null
                              ? ` *${row.variation_name}`
                              : ''
                          return (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                {row.product_name}{' '}
                                {row.is_finished == 1
                                  ? row.variation_name != null
                                    ? '(' + row.variation_name + ')'
                                    : ''
                                  : ''}
                              </TableCell>
                              <TableCell>
                                {formattedProductQty}
                                {variationName}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {order_details.delivery_date != 0 && (
                    <Typography variant="caption">
                      Delivery Date :{' '}
                      {moment(order_details.delivery_date * 1000).format(
                        'MM/DD/YYYY',
                      )}
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>

            {/* <Typography variant='h6'>Reply To Order</Typography>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <div>
                    <LabelStyle>Message Body*</LabelStyle>
                    <QuillEditor
                      id="post-content"
                       value={message}
                       onChange={(val) => setMessage(val)}             
                    />
                  </div>
                </Stack>

                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                 <Button onClick={postReply} variant='outlined' size='medium'>
                        Post a reply 
                  </Button>
                </Stack> */}
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
}
