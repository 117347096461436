import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { useCallback, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
// material
import {
  Box,
  Grid,
  Card,
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Typography,
  FormHelperText,
  Button,
  InputAdornment,
  Tooltip,
  useTheme,
} from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
// hooks
import useAuth from '../../../../hooks/useAuth'
import useIsMountedRef from '../../../../hooks/useIsMountedRef'
import { UploadAvatar } from '../../../upload'
// utils
import { fData } from '../../../../utils/formatNumber'
//
import countries from '../countries'
import Logo from '../../../../logo/image.png'
import fakeRequest from 'src/utils/fakeRequest'
import { storage } from 'src/firebase/firebase'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/DefaultValues'
import { removeAtSymbol } from 'src/utils/InputFunction'
import Label from 'src/components/Label'
import zIndex from '@material-ui/core/styles/zIndex'

// ----------------------------------------------------------------------
const getGradientStyles = (customerType) => {
  switch (customerType) {
    case 1: // Gold
      return {
        backgroundImage:
          'linear-gradient(12deg, #FFD700 20%, #FFB800 40%, #FF8C00 60%, #FFA500 100%)', // Gold-like gradient
      };
    case 2: // Silver
      return {
        backgroundImage:
          'linear-gradient(12deg, #C0C0C0 20%, #B0B0B0 40%, #A9A9A9 60%, #808080 100%)', // Silver-like gradient
      };
    case 3: // Rose Gold (Platinum)
      return {
        backgroundImage:
          'linear-gradient(12deg, #B76E79 20%, #D29EAB 40%, #FFD1DC 60%, #FFE4E1 100%)', // Rose gold gradient
      };
    case 4: // Emerald
      return {
        backgroundImage:
          'linear-gradient(12deg, #50C878 20%, #46A76A 40%, #3D915E 60%, #347A53 100%)', // Emerald-like gradient
      };
    default: // Default gradient (Black and gray)
      return {
        backgroundImage:
          'linear-gradient(12deg, #000000 20%, #2B2B2B 40%, #434343 60%, #000 100%)', // Default gradient for Stack
        backgroundColor: '#fff', // Default background color for fallback
      };
  }
};

export default function AccountGeneral({ userData,fromClient,handleCloseProfile }) {
  const theme = useTheme()
  const isMountedRef = useIsMountedRef()
  const { enqueueSnackbar } = useSnackbar()
  const { user, updateProfile } = useAuth()
  const [clockedIn, setClockedIn] = useState(true)

  const UpdateUserSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: !fromClient &&   Yup.string().required('Email is required').email(),
    tg_number:!fromClient &&  Yup.string()
    .typeError('Telegram number must be valid')
    .test(
      'not-only-spaces',
      'Telegram ID cannot be only spaces',
      (value) => value === undefined || value.trim().length > 0
    )
    .test(
      'at-least-one-field',
      'At least one of Email, Telegram, or Signal Number is required',
      function (value) {
        const { email, signal_number } = this.parent;
        return value || email || signal_number;
      }
    ),
  signal_number: !fromClient &&  Yup.string()
    .typeError('Signal number must be valid')
    .test(
      'not-only-spaces',
      'Signal ID cannot be only spaces',
      (value) => value === undefined || value.trim().length > 0 
    )
    .test(
      'at-least-one-field',
      'At least one of Email, Telegram, or Signal Number is required',
      function (value) {
        const { email, tg_number } = this.parent;
        return value || email || tg_number;
      }
    ),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: userData.first_name || '',
      last_name: userData.last_name || '',
      email: userData?.email || '',
      photoURL: userData?.profile_pic || '',
      phoneNumber: userData?.phone || '',
      pinNumber: userData?.user_pin || '',
      tg_number:userData?.tg_number ? removeAtSymbol(userData?.tg_number) : '',
      signal_number:userData?.signal_number ?removeAtSymbol(userData?.signal_number) : ''
    },

    validationSchema: UpdateUserSchema,
    onSubmit:  (values, { setErrors, setSubmitting }) => {
      try {
         fakeRequest(500)
         values.signal_number = (values.signal_number && values.signal_number != '') ? '@'+values.signal_number : '';
         values.tg_number = (values.tg_number && values.tg_number != '') ? '@'+values.tg_number : '';
         axios.post(`${REST_API_END_POINT}profile/update-${fromClient?'client':'user'}`,{
          values,user_id:userData.id
         })
         .then((res)=>{
          if(res.data.status === 1){
            window.location.reload()
            if(fromClient){
              handleCloseProfile()
            }
            enqueueSnackbar(res.data.msg,{variant:'success'})
            if (isMountedRef.current) {
              setSubmitting(false)
            }
          }else{
            enqueueSnackbar(res.data.msg,{variant:'success'})
            if (isMountedRef.current) {
              setSubmitting(false)
            }
          }
         })
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code })
          setSubmitting(false)
        }
      }
    },
  })

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      if (file) {
        const filename = new Date().getTime() + file.name
        const storageRef = storage.ref(filename)
        const uploadTask = storageRef.put(file)
        uploadTask.on(
          'state_changed',
          (snapshot) => {                
          },
          (error) => {
            console.error(error.message)
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              setFieldValue('photoURL', downloadURL)                    
            })
          },
        )
      }
    },
    [setFieldValue],
  )
  const handleClockIn = () => {
    if (!clockedIn) {
      setClockedIn(true)
      enqueueSnackbar('You have been clocked out', { variant: 'error' })
    } else {
      setClockedIn(false)
      enqueueSnackbar('You have been clocked in', { variant: 'success' })
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3, textAlign: 'center', position: 'relative' }}>
              <Label
                sx={{
                  p: '5px 15px',
                  borderRadius: '20px', // Rounded corners for a tag-like shape
                  width: 'auto', // Auto width to fit content
                  position: 'absolute', // Position the label absolutely
                  top: '10px', // Distance from the top of the card
                  left: '10px', // Distance from the left of the card
                  color: 'black',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                  ...getGradientStyles(user?.customer_type), // Apply gradient based on customer type
                }}
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              >
                {userData.customer_type_name || 'No Type Assigned'}
              </Label>
              <UploadAvatar
                accept="image/*"
                file={values?.photoURL}
                maxSize={3145728}
                onDrop={handleDrop}
                error={Boolean(touched.photoURL && errors.photoURL)}
              />

              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {touched.photoURL && errors.photoURL}
              </FormHelperText>
              <Typography variant="h6">
                {user?.first_name + ' ' + user?.last_name}
              </Typography>
              <Tooltip title={user?.email === 'admin@lemon.com' ? 'admin@bagempire.com' : user?.email} arrow>
              <Typography  sx={{ 
               maxWidth: '200px',
               whiteSpace: 'nowrap',
               overflow: 'hidden',
              textOverflow: 'ellipsis', 
              }} variant="subtitle2">
                
                {user?.email === 'admin@lemon.com'
                  ? 'admin@bagempire.com'
                  : user?.email}
              </Typography>
                  </Tooltip>
              {/* <Button
                sx={{ borderRadius: 5, mt: 1.5 }}
                onClick={handleClockIn}
                variant="contained"
                color={clockedIn ? 'info' : 'error'}
              >
                {clockedIn ? 'Clock In' : 'Clock Out'}
              </Button> */}
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="First Name"
                    {...getFieldProps('first_name')}
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    {...getFieldProps('last_name')}
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'column' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Stack>
                {fromClient && (
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    fullWidth                               
                    label="Telegram"                   
                    {...getFieldProps('tg_number')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span style={{ color: 'black' }}>@</span>
                        </InputAdornment>
                      ),
                      style: { color: 'black' },
                    }}
                    error={Boolean(touched.tg_number && errors.tg_number)}
                    helperText={touched.tg_number && errors.tg_number}                   
                  />
                   <TextField
                    fullWidth                
                    label="Signal"                              
                    {...getFieldProps('signal_number')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span style={{ color: 'black' }}>@</span>
                        </InputAdornment>
                      ),
                      style: { color: 'black' },
                    }}
                    error={Boolean(touched.signal_number && errors.signal_number)}
                    helperText={touched.signal_number && errors.signal_number}                 
                  />
                </Stack>
                )}
                {!fromClient && (
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    {...getFieldProps('phoneNumber')}
                  />
                  <TextField
                    fullWidth
                    label="Pin Number"
                    {...getFieldProps('pinNumber')}
                  />             
                </Stack>
                  )}
              </Stack>

              <Box
                sx={{ mt: 4.5, display: 'flex', justifyContent: 'flex-end' }}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save Changes
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}
