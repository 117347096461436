import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useSnackbar } from 'notistack'
import { useLocation, useNavigate } from 'react-router-dom'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
// material
import {
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles'
import { LoadingButton } from '@material-ui/lab'
import {
  Card,
  Chip,
  Grid,
  Stack,
  Radio,
  Switch,
  Select,
  TextField,
  InputLabel,
  Typography,
  RadioGroup,
  FormControl,
  Autocomplete,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
  Box,
  Button,
  DialogActions,
  Checkbox,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CardContent,
  Divider,
} from '@material-ui/core'

import {
  UploadAvatar,
  UploadMultiFile,
  UploadSingleFile,
} from 'src/components/upload'
import useAuth from 'src/hooks/useAuth'
import { OUNCE_CONSTANT, REST_API_END_POINT } from 'src/constants/DefaultValues'
import axios from 'axios'
import { zeroPad } from 'src/constants/Helper'
import { QuillEditor } from 'src/components/editor'
import moment from 'moment'
import ReactToPrint from 'react-to-print'
import Scrollbar from 'src/components/Scrollbar'
//

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}))

// ----------------------------------------------------------------------

const getData = (source, ids) => {
  console.log('source', source)
  console.log('ids', ids)
  if (!ids) return []
  return ids
    .split(',')
    .map((id) => source.find((item) => item.id == id))
    .filter((item) => item !== undefined)
}

export default function InvoiceView({
  order_data,
  order_products,
  facility_data,
  setOpenInvoice,
}) {
  const theme = useTheme();
    let  current_currency_symbol = '$';
    let sub_total = 0;
    let totalQuantity = 0;
    let sales_tax = 0;
    let total_sales_tax =0;
    const newTotal = parseFloat((order_data?.cash || 0) + (order_data?.zelleTotal || 0) + (order_data?.cryptoTotal || 0)  + (order_data.partial_payment ||0)).toFixed(2)
    const balanceTaxAmount = parseFloat(parseFloat(newTotal)?.toFixed(2) - parseFloat(order_data?.return_amount)?.toFixed(2))?.toFixed(2)
    const handleClose=()=>{
        setOpenInvoice(false)
    }
    const componentRef = useRef();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CardContent>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                {facility_data ? facility_data.facility_name : ''}
              </Typography>
              {facility_data && facility_data.facility_address && (
                <Typography>{facility_data.facility_address}</Typography>
              )}
              {facility_data && facility_data.facility_phone && (
                <Typography>{facility_data.facility_phone}</Typography>
              )}
              {facility_data && facility_data.facility_email && (
                <Typography>{facility_data.facility_email}</Typography>
              )}
              {facility_data && facility_data.license_number && (
                <Typography>Lic No: {facility_data.license_number}</Typography>
              )}
            </Grid>
            {order_data && (
              <Grid item style={{ textAlign: 'right' }}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  Invoice
                </Typography>
                <Typography>
                  INVOICE # {zeroPad(order_data.order_number, 6)}
                  <br />
                  DATE:{' '}
                  {moment(order_data.timestamp * 1000).format('MMMM DD, YYYY')}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Divider style={{ margin: theme.spacing(4, 0) }} />

          {order_data && (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  TO
                </Typography>
                <Typography>
                  Customer Name: {order_data.customer_name}
                  <br />
                  {order_data.billing_address && (
                    <>
                      Customer Address: {order_data.billing_address}
                      <br />
                    </>
                  )}
                  {order_data.customer_phone && (
                    <>Phone: {order_data.customer_phone}</>
                  )}
                </Typography>
              </Grid>
              {order_data && (
                <Grid item style={{ textAlign: 'right' }}>
                  <Typography variant="h5" fontWeight="bold">
                    SHIPPED TO
                  </Typography>
                  <Typography>
                    Customer Name: {order_data.customer_name}
                    <br />
                    {order_data.shipping_address && (
                      <>
                        Customer Address: {order_data.shipping_address}
                        <br />
                      </>
                    )}
                    {order_data.customer_phone && (
                      <>Phone: {order_data.customer_phone}</>
                    )}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}

          {order_data && (
            <Scrollbar>
              <TableContainer sx={{minWidth:400,whiteSpace:'nowrap'}}>
            <Table size='small' sx={{mt:3}}>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell align="left">Price</TableCell>
                  <TableCell align="left">Quantity</TableCell>
                  {/* <TableCell align="left">Price</TableCell> */}
                  <TableCell align="left">Sales Tax</TableCell>
                  <TableCell align="left">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order_products.map((row, index) => {
                  // let taxAmount =parseFloat(row.product_unit_price * 5 /100).toFixed(2)
                  let taxAmount =row.product_sales_tax
                  sales_tax += taxAmount
                  total_sales_tax += taxAmount * row.product_qty
                  totalQuantity += row.product_qty
                  // sales_tax += parseFloat(row.product_sales_tax);
                  // let line_total = parseFloat(row.product_unit_price) + parseFloat(row.product_excise_tax) + parseFloat(row.product_sales_tax);
                  // sub_total += parseFloat(row.product_unit_price)
                  let line_total = parseFloat(row.product_unit_price * row.product_qty) + Number(taxAmount * row.product_qty)
                  sub_total += parseFloat(row.product_unit_price * row.product_qty)              
                  // let line_total = parseFloat(row.product_unit_price) * parseFloat(row.product_qty) + parseFloat(row.product_excise_tax) + parseFloat(row.product_sales_tax);
                  // sub_total += parseFloat(row.product_unit_price) * parseFloat(row.product_qty)
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      {/* <TableCell>{row.product_name} {row.variation_name && `(${row.variation_name})`}</TableCell> */}
                      <TableCell>{row.product_name} </TableCell>
                      <TableCell align="left">{current_currency_symbol + parseFloat(row.product_unit_price)?.toFixed(2)}</TableCell>
                      <TableCell align="left">{parseFloat(row.product_qty)?.toFixed(2)}</TableCell>
                      {/* <TableCell align="left">{current_currency_symbol + parseFloat(row.product_unit_price) * parseFloat(row.product_qty)?.toFixed(2)}</TableCell> */}
                      {/* old */}
                      {/* <TableCell align="left">{current_currency_symbol + parseFloat(row.product_sales_tax)?.toFixed(2)}</TableCell>  */}      
                       {/*new  */}
                      <TableCell align="left">{current_currency_symbol + parseFloat(taxAmount * row.product_qty)?.toFixed(2)}</TableCell>
                      <TableCell align="left">{current_currency_symbol + parseFloat(line_total)?.toFixed(2)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            </TableContainer>
            </Scrollbar>
          )}

          {order_data && (
            <Grid container justifyContent="flex-end" sx={{ ml:-3.5,mt:4 }}>
              <Grid xs={12} sm={6} md={4} >
              <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
                <Typography>Total Quantity :</Typography>
                <Typography>{parseFloat(totalQuantity)?.toFixed(2)}</Typography>
              </Stack>

              <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
                <Typography>Total Sales Tax : </Typography>
                <Typography>{current_currency_symbol + parseFloat(total_sales_tax )?.toFixed(2)}</Typography>
              </Stack>
              <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
                <Typography>Sub Total : </Typography>
                <Typography>
                  {current_currency_symbol + (
                    parseFloat(order_data.total || 0) +
                    parseFloat(order_data.point_discount || 0) +
                    parseFloat(order_data.coupon_discount || 0) -
                    parseFloat(order_data?.shippingRate || 0)
                  ).toFixed(2)}
                </Typography>
              </Stack>
              {
                ((order_data?.point_discount + order_data?.coupon_discount) > 0) && (
                  <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
                  <Typography>Discount : </Typography>
                  <Typography>{current_currency_symbol + parseFloat(order_data?.point_discount + order_data?.coupon_discount).toFixed(2) }</Typography>
                </Stack>
                )
              }
              {
                order_data?.isShipping ? (
                  <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
                  <Typography>Shipping Charges : </Typography>
                  <Typography>{current_currency_symbol + parseFloat(order_data?.shippingRate).toFixed(2) }</Typography>
                </Stack>
                ) : ''
              }
              <Divider/>
     
     <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'} >
            <Typography>Cash : </Typography>
            <Typography>${order_data?.cash? order_data?.cash : 0.00}</Typography>
          </Stack>

      <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
            <Typography>Crypto (6%) : </Typography>
            <Typography>${order_data?.cryptoTotal?order_data?.cryptoTotal : 0.00}</Typography>
          </Stack>

          <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'} >
            <Typography>Zelle (6%) : </Typography>
            <Typography>${order_data?.zelleTotal?order_data?.zelleTotal :0.00} </Typography>
          </Stack>

       
          <Divider/>

          <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
            <Typography>Total Paid : </Typography>
            <Typography>${parseFloat((order_data?.cash || 0) + (order_data?.zelleTotal || 0) + (order_data?.cryptoTotal || 0)).toFixed(2) }</Typography>
            {/* <Typography>
              ${parseFloat(
                (order_data?.cash || 0) +
                (order_data?.zelleTotal || 0) +
                (order_data?.point_discount || 0) +
                (order_data?.cryptoTotal || 0)
              ).toFixed(2) !== '0.00' ? 
                parseFloat(
                  (order_data?.cash || 0) +
                  (order_data?.zelleTotal || 0) +
                  (order_data?.point_discount || 0) +
                  (order_data?.cryptoTotal || 0)
                ).toFixed(2) : 
                order_data?.sub_total}
            </Typography> */}

          </Stack>

          { order_data.payment_status === 0 &&
            <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
              <Typography  sx={{color:"red"}}>Payment Due :</Typography>
              <Typography sx={{color:"red"}}>{current_currency_symbol + parseFloat(order_data.total)?.toFixed(2)}</Typography>
            </Stack>
          }
          { order_data.payment_status === 2 &&
            <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
              <Typography  sx={{color:"red"}}>Payment Due :</Typography>
              <Typography sx={{color:"red"}}>{current_currency_symbol + parseFloat(order_data.partial_payment)?.toFixed(2)}</Typography>
            </Stack>
          }
          { order_data.payment_status === 1 &&
            <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
              <Typography  sx={{color:"red"}}>Payment Due :</Typography>
              <Typography sx={{color:"red"}}>{current_currency_symbol + 0}</Typography>
            </Stack>
          }
      

          <Divider/>

          {/* <Stack spacing={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography sx={{fontWeight:'bold'}}>Grand Total{order_data.fully_returned == 1 ? ' (Refunded) :' : ' :' }</Typography> */}

          <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
            <Typography sx={{fontWeight:'bold'}}>Grand Total : </Typography>
            { order_data.payment_status === 0 ?
              <Typography sx={{fontWeight:'bold'}}>{current_currency_symbol}{parseFloat(order_data.total || 0).toFixed(2)}</Typography>
            :
              <Typography sx={{fontWeight:'bold'}}>{current_currency_symbol}{parseFloat((order_data?.cash || 0) + (order_data?.zelleTotal || 0) + (order_data?.cryptoTotal || 0)  + (order_data.partial_payment ||0)).toFixed(2)}</Typography>
            }
          </Stack>


          </Grid>
            </Grid>
          )}

          {order_data && (
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              style={{ marginTop: theme.spacing(4) }}
            >
              {
              order_data?.refund == 1 ? 
              <>
              Your refund has been processed. Zelle and Crypto transaction fees won’t be refunded.
              {/* Your refund of {current_currency_symbol + order_data?.return_amount} has been processed. The {current_currency_symbol + balanceTaxAmount} is non-refundable fee. */}
              <br /> Thank you for choosing us!
              </>
              :
              order_data.payment_status === 1
                ? 'Payment received. Thank you for choosing us!'
                : 'Due upon receipt'}
              <br />
              If you have any questions concerning this invoice, contact{' '}
              {facility_data &&
                facility_data.facility_email &&
                `<${facility_data.facility_email}>`}
            </Typography>
          )}
        </CardContent>
        <Grid m={2}>
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: '20px' }}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
            copyStyles={true}
            pageStyle={''}
          />
          <div style={{ display: 'none' }}>
            <PrintOrderInvoice
              ref={componentRef}
              order_data={order_data}
              order_products={order_products}
              facility_data={facility_data}
            />
          </div>

          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
const PrintOrderInvoice=forwardRef(

  ({order_data, order_products, facility_data,},ref)=>{
    const theme = useTheme();
    let  current_currency_symbol = '$';
    let sub_total = 0;
    let totalQuantity = 0;
    let sales_tax = 0;
    let total_sales_tax =0;
    const newTotal = parseFloat((order_data?.cash || 0) + (order_data?.zelleTotal || 0) + (order_data?.cryptoTotal || 0)  + (order_data.partial_payment ||0)).toFixed(2)
    const balanceTaxAmount = parseFloat(parseFloat(newTotal)?.toFixed(2) - parseFloat(order_data?.return_amount)?.toFixed(2))?.toFixed(2)

return(
    <Grid item ref={ref}  xs={12}>
<CardContent>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                {facility_data ? facility_data.facility_name : ''}
              </Typography>
              {facility_data && facility_data.facility_address && (
                <Typography>{facility_data.facility_address}</Typography>
              )}
              {facility_data && facility_data.facility_phone && (
                <Typography>{facility_data.facility_phone}</Typography>
              )}
              {facility_data && facility_data.facility_email && (
                <Typography>{facility_data.facility_email}</Typography>
              )}
              {facility_data && facility_data.license_number && (
                <Typography>Lic No: {facility_data.license_number}</Typography>
              )}
            </Grid>
            {order_data && (
              <Grid item style={{ textAlign: 'right' }}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  {order_data.refund == 1 ? 'Refund Invoice' : 'Invoice'}
                </Typography>
                <Typography>
                  INVOICE # {zeroPad(order_data.order_number, 6)}
                  <br />
                  DATE:{' '}
                  {moment(order_data.timestamp * 1000).format('MMMM DD, YYYY')}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Divider style={{ margin: theme.spacing(4, 0) }} />

          {order_data && (
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  TO
                </Typography>
                <Typography>
                  Customer Name: {order_data.customer_name}
                  <br />
                  {order_data.billing_address && (
                    <>
                      Customer Address: {order_data.billing_address}
                      <br />
                    </>
                  )}
                  {order_data.customer_phone && (
                    <>Phone: {order_data.customer_phone}</>
                  )}
                </Typography>
              </Grid>
              {order_data && (
                <Grid item style={{ textAlign: 'right' }}>
                  <Typography variant="h5" fontWeight="bold">
                    SHIPPED TO
                  </Typography>
                  <Typography>
                    Customer Name: {order_data.customer_name}
                    <br />
                    {order_data.shipping_address && (
                      <>
                        Customer Address: {order_data.shipping_address}
                        <br />
                      </>
                    )}
                    {order_data.customer_phone && (
                      <>Phone: {order_data.customer_phone}</>
                    )}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}

          {order_data && (
            <Table size="small" sx={{ mt: 3 }}>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell align="left">Price</TableCell>
                  <TableCell align="left">Quantity</TableCell>
                  {/* <TableCell align="left">Price</TableCell> */}
                  <TableCell align="left">Sales Tax</TableCell>
                  <TableCell align="left">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order_products.map((row, index) => {
                  // sales_tax += parseFloat(row.product_sales_tax);
                  // let line_total = parseFloat(row.product_unit_price) * parseFloat(row.product_qty) + parseFloat(row.product_excise_tax) + parseFloat(row.product_sales_tax);
                  // sub_total += parseFloat(row.product_unit_price) * parseFloat(row.product_qty) + parseFloat(row.product_excise_tax) + parseFloat(row.product_sales_tax);

                  // let taxAmount =parseFloat(row.product_unit_price * 5 /100).toFixed(2)
                  let taxAmount = row.product_sales_tax
                  totalQuantity += row.product_qty
                  sales_tax += taxAmount
                  total_sales_tax += taxAmount * row.product_qty
                  let line_total =
                    parseFloat(row.product_unit_price * row.product_qty) +
                    Number(taxAmount * row.product_qty)
                  sub_total += parseFloat(
                    row.product_unit_price * row.product_qty,
                  )

                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {row.product_name}{' '}
                        {row.variation_name && `(${row.variation_name})`}
                      </TableCell>
                      <TableCell align="left">
                        {current_currency_symbol +
                          parseFloat(row.product_unit_price)?.toFixed(2)}
                      </TableCell>
                      <TableCell align="left">
                        {parseFloat(row.product_qty)?.toFixed(2)}
                      </TableCell>
                      {/* <TableCell align="left">{current_currency_symbol + (parseFloat(row.product_unit_price) * parseFloat(row.product_qty))?.toFixed(2)}</TableCell> */}
                      {/* <TableCell align="left">{current_currency_symbol + parseFloat(row.product_sales_tax)?.toFixed(2)}</TableCell> */}
                      <TableCell align="left">
                        {current_currency_symbol +
                          parseFloat(taxAmount * row.product_qty)?.toFixed(2)}
                      </TableCell>
                      <TableCell align="left">
                        {current_currency_symbol +
                          parseFloat(line_total)?.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}

          {order_data && (
            <Grid container justifyContent="flex-end" sx={{ ml:-3.5,mt:4 }}>
              <Grid xs={12} sm={6} md={4} >
                <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
                  <Typography variant='h6' sx={{fontWeight:400}}>Total Quantity :</Typography>
                  <Typography variant='h6' sx={{fontWeight:400}}>{ parseFloat(totalQuantity)?.toFixed(2)}</Typography>
                </Stack>

                <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
                  <Typography variant='h6' sx={{fontWeight:400}}>Total Sales Tax : </Typography>
                  <Typography variant='h6' sx={{fontWeight:400}}>{current_currency_symbol + parseFloat(total_sales_tax )?.toFixed(2)}</Typography>
                </Stack>
                <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
                  <Typography variant='h6' sx={{fontWeight:400}}>Sub Total : </Typography>
                  <Typography variant='h6' sx={{fontWeight:400}}>
                    {current_currency_symbol + (
                      parseFloat(order_data.total || 0) +
                      parseFloat(order_data.point_discount || 0) +
                      parseFloat(order_data.coupon_discount || 0) -
                      parseFloat(order_data?.shippingRate || 0)
                    ).toFixed(2)}
                  </Typography>
                </Stack>
                {
                  ((order_data?.point_discount + order_data?.coupon_discount) > 0) && (
                    <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
                    <Typography variant='h6' sx={{fontWeight:400}}>Discount : </Typography>
                    <Typography variant='h6' sx={{fontWeight:400}}>{current_currency_symbol + parseFloat(order_data?.point_discount + order_data?.coupon_discount).toFixed(2) }</Typography>
                  </Stack>
                  )
                }
                {
                  (order_data?.isShipping) ? (
                    <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
                    <Typography variant='h6' sx={{fontWeight:400}}>Shipping Charges : </Typography>
                    <Typography variant='h6' sx={{fontWeight:400}}>{current_currency_symbol + parseFloat(order_data?.shippingRate).toFixed(2) }</Typography>
                  </Stack>
                  ) : ''
                }
                <Divider/>

          <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
            <Typography variant='h6' sx={{fontWeight:400}}>Cash : </Typography>
            <Typography variant='h6' sx={{fontWeight:400}}>${order_data?.cash? order_data?.cash : 0.00}</Typography>
          </Stack>

          <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
            <Typography variant='h6' sx={{fontWeight:400}}>Crypto (6%) : </Typography>
            <Typography variant='h6' sx={{fontWeight:400}}>${order_data?.cryptoTotal?order_data?.cryptoTotal : 0.00}</Typography>
          </Stack>

          <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
            <Typography variant='h6' sx={{fontWeight:400}}>Zelle (6%) : </Typography>
            <Typography variant='h6' sx={{fontWeight:400}}>${order_data?.zelleTotal?order_data?.zelleTotal :0.00} </Typography>
          </Stack>


          <Divider/>

          <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
            <Typography variant='h6' sx={{fontWeight:400}}>Total Paid: </Typography>
            <Typography variant='h6' sx={{fontWeight:400}}>${parseFloat((order_data?.cash || 0) + (order_data?.zelleTotal || 0) + (order_data?.cryptoTotal || 0)).toFixed(2)}</Typography>
          </Stack>

          { order_data.payment_status === 0 &&
            <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
              <Typography  variant='h6' sx={{fontWeight:400, color:"red"}}>Payment Due :</Typography>
              <Typography  variant='h6' sx={{fontWeight:400, color:"red"}}>{current_currency_symbol + parseFloat(order_data.total)?.toFixed(2)}</Typography>
            </Stack>
          }
          { order_data.payment_status === 2 &&
            <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
              <Typography variant='h6' sx={{fontWeight:400, color:"red"}}>Payment Due :</Typography>
              <Typography variant='h6' sx={{fontWeight:400,color:"red"}}>{current_currency_symbol + parseFloat(order_data.partial_payment)?.toFixed(2)}</Typography>
            </Stack>
          }
          { order_data.payment_status === 1 &&
            <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
              <Typography variant='h6' sx={{fontWeight:400, color:"red"}}>Payment Due :</Typography>
              <Typography variant='h6' sx={{fontWeight:400, color:"red"}}>{current_currency_symbol + 0}</Typography>
            </Stack>
          }
          <Divider/>

          <Stack spacing={2} direction={'row'} textAlign="right" justifyContent={'space-between'}>
            <Typography variant='h6' sx={{fontWeight:'bold'}}>Grand Total : </Typography>
            { order_data.payment_status === 0 ?
              <Typography variant='h6' sx={{fontWeight:'bold'}}>{current_currency_symbol}{parseFloat(order_data.total || 0).toFixed(2)}</Typography>
            :
              <Typography variant='h6' sx={{fontWeight:'bold'}}>{current_currency_symbol}{parseFloat((order_data?.cash || 0) + (order_data?.zelleTotal || 0) + (order_data?.cryptoTotal || 0) + (order_data.partial_payment ||0)).toFixed(2)}</Typography>
            }
          </Stack>
          
          </Grid>
            </Grid>
          )}

          {order_data && (
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              style={{ marginTop: theme.spacing(4) }}
            >
              {
               order_data?.refund == 1 ? 
               <>
               Your refund has been processed. Zelle and Crypto transaction fees won’t be refunded.
               {/* Your refund of {current_currency_symbol + order_data?.return_amount} has been processed. The {current_currency_symbol + balanceTaxAmount} is non-refundable fee. */}
               <br /> Thank you for choosing us!
               </>
               :
                order_data.payment_status === 1
                ? 'Payment received. Thank you for choosing us!'
                : 'Due upon receipt'}
              <br />
              If you have any questions concerning this invoice, contact{' '}
              {facility_data &&
                facility_data.facility_email &&
                `<${facility_data.facility_email}>`}
            </Typography>
          )}
        </CardContent>
      </Grid>
    )
  },
)
