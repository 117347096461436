import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack, Box, TextField } from '@material-ui/core';
// routes
import LogoNew from '../../logo/loader-logo1.png'
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#232323'
}));


// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider sx={{borderColor:'#232323'}} />
      <Box maxWidth="lg">
        <Grid
          container
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 2,pb:2, pl: { xs: 3, sm: 3, md: 6 }, pr: { xs: 3, sm: 3, md: 6 }, backgroundColor: '#111111' }}
        >
          <Grid item xs={12} md={4} container justifyContent={{xs:"center", sm:"center", md: "flex-start"}} alignItems={{xs:"center", sm:"center", md: "flex-start"}}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <IconButton>
                <Stack className='jam--telegram' sx={{fontSize:'3.1rem',color:'common.white'}}/>
              </IconButton>
              <Stack direction={'column'} alignItems={'start'} sx={{color:'common.white'}} spacing={0.5}>
                <Typography variant='h6'>Telegram Live Support</Typography>
                <Typography variant='subtitle2'>@BAGEMPIREORDERS</Typography>
              </Stack>
            </Stack>
          </Grid>
            
          <Grid item xs={12} md={4} container justifyContent={{xs:"center", sm:"center", md: "flex-end"}} alignItems={{xs:"center", sm:"center", md: "flex-end"}} sx={{ml:{xs:-1,sm:-2,md:0}}}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <IconButton>
                <Stack className='simple-icons--signal' sx={{fontSize:'3rem',color:'common.white'}}/>
              </IconButton>
              <Stack direction={'column'} alignItems={'start'} sx={{color:'common.white'}} spacing={0.5}>
                <Typography variant='h6'>Signal Live Support</Typography>
                <Typography variant='subtitle2'>@BAGEMPIREORDERS.83</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Divider sx={{borderColor:'#232323'}} />
        <Grid
          container
          justifyContent={ 'center' }
          sx={{
            textAlign: 'center',
            backgroundColor: 'black',
            alignItems:'center',
            alignContent:'center',
            flexDirection:'column',
            p:2
          }}
        >
          <ScrollLink to="move_top" spy smooth>
                <img src={LogoNew} style={{cursor:'pointer',width:'90px',height:'90px',}} alt='Logo' />
            </ScrollLink>
            <Typography
              component="p"
              variant="body2"
              sx={{
                fontSize: 14,
                mt:2,
                textAlign: { xs: 'center', md: 'left' },
                color: '#888888',
              }}
            >
              Copyright © skyniche Production, All Rights Reserved
            </Typography>
        </Grid>
      </Box>
    </RootStyle>
  );
}