import { filter } from 'lodash'
import { Icon } from '@iconify/react'
import { sentenceCase } from 'change-case'
import { useState, useEffect } from 'react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
// material
import { useTheme } from '@material-ui/core/styles'
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Switch,
  Box,
  Badge,
  Paper,
  Divider,
  TextField,
  MenuItem,
} from '@material-ui/core'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { getUserList, deleteUser } from 'src/redux/slices/user'
// routes
import { PATH_DASHBOARD } from 'src/routes/paths'
// components
import Page from 'src/components/Page'
import Label from 'src/components/Label'
import Scrollbar from 'src/components/Scrollbar'
import SearchNotFound from 'src/components/SearchNotFound'
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs'
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from 'src//components/_dashboard/user/list'
import axios from 'axios'
import { REST_API_END_POINT } from 'src/constants/DefaultValues'
import { useSnackbar } from 'notistack'
import useAuth from 'src/hooks/useAuth'
import DeleteConfirmationPopup from 'src/pages/dashboard/DeleteConfirmationPopup'
import LoadingScreen from 'src/components/LoadingScreen'
import moment from 'moment'
import OrderMenu from './OrderMenu'
import Modal from '../../ProjectModel'
import ViewOrderDetails from './ViewOrderDetails'
import InvoiceView from './InvoiceView'
import OrderInvoiceEdit from './orderInvoiceEdit'
import PrintOrderLabels from './printOrderLabels'
import VoidOrder from './voidOrder'
import CompleteOrder from './completeOrder'
import { Close } from '@material-ui/icons'
import { MIconButton } from 'src/components/@material-extend'
import RefundOrder from './RefundOrder'
import DeleteConfirmation from 'src/components/_dashboard/pop-ups/DeleteConfirmation'
// ----------------------------------------------------------------------
export const CUSTOMER_ORDER_STATUS = [
  { label: 'Waiting for Approval', value: '0', badge: 'warning', key: 0 },
  { label: 'Approved', value: '1', badge: 'info', key: 1 },
  { label: 'In Process', value: '2', badge: 'secondary', key: 2 },
  { label: 'Completed', value: '3', badge: 'success', key: 3 },
  { label: 'Canceled', value: '4', badge: 'error', key: 4 },
  { label: 'Voided', value: '5', badge: 'default', key: 5 },
  { label: 'Approved', value: '6', badge: 'info', key: 6 },
]
const TABLE_HEAD = [
  // { id: 'id', label: 'Id', alignRight: false },
  { id: 'order_id', label: 'Order Id', alignLeft: true },
  { id: 'patient_name', label: 'Patient Name', alignLeft: true },
  { id: 'order_date', label: 'Order Date', alignLeft: true },
  // { id: 'metric_status', label: 'Metric Status', alignRight: false },
  // { id: 'delivery_date', label: 'Delivery Date', alignRight: false },
  { id: 'payment_mode', label: 'Payment Mode', alignLeft: true },
  { id: 'payment_status', label: 'Payment Status', alignLeft: true },
  { id: 'status', label: 'Status', alignLeft: true },
  { id: 'options', label: 'Options', alignLeft: true },
]

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    return filter(
      array,
      (_user) =>
        _user?.vendor_name?.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    )
  }
  return stabilizedThis.map((el) => el[0])
}

const FILTER = [
  { id: 1, label: 'Recent',value:'recent' }, 
  { id: 2, label: 'Oldest',value:'oldest' }, 
  { id: 3, label: 'Today',value:'today_orders' }, 
  { id: 4, label: 'Week', value:'week_order' }, 
  { id: 5, label: 'Month', value:'month_order' }, 
];

export default function AllOrdersPOS() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [orders, setOrders] = useState([])
  const [customers, setCustomers] = useState([])
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('id')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [isDeletePopUpOpen, setDeletePopUpOpen] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)
  const [openView, setOpenview] = useState(false)
  const [viewDetails, setViewDetails] = useState({})
  const [currencies, setCurrencies] = useState([])
  const [orderProduct, setOrderProducts] = useState([])
  const [facilityData, setFacilityData] = useState({})
  const [wholeOrderData, setWholeOrderData] = useState({})
  const [invoiceOrderData, setInvoiceOrderData] = useState(null)
  const [openInvoice, setOpenInvoice] = useState(false)
  const [openInvoiceEdit, setOpenInvoiceEdit] = useState(false)
  const [openPrint, setOpenPrint] = useState(false)
  const [openComplete, setOpenComplete] = useState(false)
  const [openVoid, setOpenVoid] = useState(false)
  const [userPrevileges, setUserPrevileges] = useState([])
  const [orderId, setOrderId] = useState('')
  const navigate = useNavigate()
  const [payment, setPayment] = useState(false)
  const [adjust, setAdjust] = useState(false)
  const [drawers, setDrawers] = useState([])
  const [drawersData, setDrawersData] = useState([])
  const [drawerId, setDrawerId] = useState(null)
  const [currencyCode, setCurrencyCode] = useState('USD')
  const [cashTotal, setCashTotal] = useState([])
  const [openRefund, setOpenRefund] = useState(false)
  const [customerId,setCustomerId] = useState(null)
  const [paymentStatus,setPaymentStatus] = useState('')
  const [filterItem,setFilterItem] = useState('recent')
  const [selected, setSelected] = useState({});
  const [checked,setChecked] = useState({})
  const [trueIDs,setTrueIDs] = useState([])
  const [deleteFull,setDeleteFull] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [mainChecked, setMainChecked] = useState(false)

  const handleOpenInvoice=()=>{
    setOpenInvoice(true) 
  }
  const fetchData = async (filter_by) => {
    if(user?.permissions?.some(permission => ['List All Orders'].includes(permission))){
    setIsLoading(true)
    try {
      const response = await axios.post(
        `${REST_API_END_POINT}point-of-sale/get-all-orders`,
        { facility_id: user?.facility_id,
          filter_by,
          no_ecommerce:true

         },
      )
      if (response.data.status === 1) {    
        
        setCustomers(response.data.customer)
        let filteredOrders = response.data.orders.filter(row=>row.status !== 7 && row.is_pickup !== 1 )
        setOrders(filteredOrders)
        // setSelected([])
      } else {
        setCustomers([])
        setSelected([])
      }
    } catch (error) {
      console.log('Error fetching data : ', error)
      setCustomers([])
      setSelected([])
    } finally {
      setIsLoading(false)
    }
  }
  }
  const handleUserPrevileges = () => {
    axios
      .post(`${REST_API_END_POINT}dashboard/get-user-previlages`, {
        user_id: user.id,
        facility_id: user.facility_id,
      })
      .then((res) => {
        if (res.data.status === 1) {
          setUserPrevileges(res.data.user_privileges)
        }
      })
  }

  useEffect(() => {
    fetchData(FILTER[0]?.value)
    handleUserPrevileges()
  }, [refresh])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const currentPageOrders = orders.slice(page * rowsPerPage, (page + 1) * rowsPerPage)

  const handleSelectAllClick = () => {
    const newChecked = { ...checked }
    const allSelected = currentPageOrders.every((order) => newChecked[order.order_id])
    currentPageOrders.forEach((order) => {
      newChecked[order.order_id] = !allSelected
    })
    setChecked(newChecked)
    setMainChecked(!allSelected)
    setIndeterminate(false)
  }

  const handleClick = (order_id) => {
    const newChecked = { ...checked, [order_id]: !checked[order_id] }
    setChecked(newChecked)
    const totalChecked = currentPageOrders.filter((order) => newChecked[order.order_id]).length
    setMainChecked(totalChecked === currentPageOrders.length)
    setIndeterminate(totalChecked > 0 && totalChecked < currentPageOrders.length)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setMainChecked(false)
    setIndeterminate(false)
  }
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    setMainChecked(false) 
    setIndeterminate(false)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }



  const handleDeleteProducts = () => {
    setDeleteItem('multiple')
    setDeletePopUpOpen(true)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders?.length) : 0

  const filteredUsers = applySortFilter(
    orders,
    getComparator(order, orderBy),
    filterName,
  )

  const isUserNotFound = filteredUsers.length === 0

  const cancelOrders = () => {
    const idsToDelete = Object.keys(checked)?.filter(id => checked[id])?.map(id => Number(id)); 
    if (idsToDelete === 0) {
      enqueueSnackbar('Choose a order', { variant: 'error' })
    } else {
      axios
        .post(`${REST_API_END_POINT}point-of-sale/cancel-order`, {
          user_id: user.id,
          facility_id: user.facility_id,
          order_ids: idsToDelete,
        })
        .then((res) => {
          if (res.data.status === 1) {
            fetchData(FILTER[0]?.value)
            setSelected([])
            enqueueSnackbar(res.data.message, { variant: 'success' })
          } else {
            enqueueSnackbar(res.data.message, { variant: 'error' })
          }
        })
        .catch((err) => {
          console.log(err)
          enqueueSnackbar(res.data.message, { variant: 'error' })
        })
    }
  }
  const handleview = (order_id) => {
    axios
      .post(`${REST_API_END_POINT}point-of-sale/view-orders`, {
        facility_id: user.facility_id,
        user_id: user.id,
        order_id,
      })
      .then((res) => {
        if (res.data.status === 1) {
          console.log("dataaaaa++",res.data)
          setViewDetails(res.data.order_data)
          setCurrencies(res.data.currencies)
          setOpenview(true)
        }
      })
  }
  const handleApprove = (order_id) => {
    axios.post(`${REST_API_END_POINT}`)
  }

function groupOrderProducts(orderProducts) {
  const groupedProducts = {};

  orderProducts.forEach(product => {
    const key = `${product.product_id}-${product.product_unit_price}`;
    if (groupedProducts[key]) {
      groupedProducts[key].product_qty += product.product_qty || 1;
    } else {
      groupedProducts[key] = {
        ...product,
        product_qty: product.product_qty || 1,
      };
    }
  });

  return Object.values(groupedProducts);
}

  const handleInvoice = (order_id) => {
    axios
      .post(`${REST_API_END_POINT}point-of-sale/invoice-post`, {
        facility_id: user.facility_id,
        order_id,
      })
      .then((res) => {
        if (res.data.status === 1) {
          setCurrencies(res.data.currencies)
          setInvoiceOrderData(res.data.order_data)
          // setOrderProducts(res.data.orderProduct)
          const groupedProducts = groupOrderProducts(res.data.orderProduct);
          setOrderProducts(groupedProducts);
          setFacilityData(res.data.facilityData)
          setOpenInvoice(true)
        }
      })
  }
  const handleEditInvoice = (orderId) => {
    setOrderId(orderId)
    axios
      .post(`${REST_API_END_POINT}point-of-sale/get-order-details`, {
        facility_id: user.facility_id,
        user_id: user.id,
        order_id: orderId,
      })
      .then((res) => {
        if (res.data.status === 1) {
          setOpenInvoiceEdit(true)
          setCurrencies(res.data.currencies)
          setInvoiceOrderData(res.data.order_data)
        }
      })
  }
  const handlePrint = (order_id) => {
    axios
      .post(`${REST_API_END_POINT}point-of-sale/print_labels`, {
        facility_id: user.facility_id,
        order_id,
        user_id: user.id,
      })
      .then((res) => {
        if (res.data.status === 1) {
          setOpenPrint(true)
          setCurrencies(res.data.currencies)
          setInvoiceOrderData(res.data.orderData)
          setFacilityData(res.data.facility_data)
          setWholeOrderData(res.data.whole_order_data)
          setOrderProducts(res.data.order_products)
          // const groupedProducts = groupOrderProducts(res.data.order_products);
          // setOrderProducts(groupedProducts);
        }
      })
  }

  const handleComplete = (orderId) => {
    setOrderId(orderId)
    axios
      .post(`${REST_API_END_POINT}point-of-sale/get-order-details`, {
        facility_id: user.facility_id,
        user_id: user.id,
        order_id: orderId,
      })
      .then((res) => {
        if (res.data.status === 1) {
          setOpenComplete(true)
          setCurrencies(res.data.currencies)
          setInvoiceOrderData(res.data.order_data)
        }
      })
  }
  const handleRefund = (orderId) => {
    try{
    setOrderId(orderId)
    axios.post(`${REST_API_END_POINT}point-of-sale/data-for-refund-order`, {
        facility_id: user.facility_id,
        user_id: user.id,
        order_id: orderId,
      })
      .then((res) => {
        if (res.data.status === 1) {
          let drawers = res.data.drawers
          var data = res.data.data;
          let order_products = data.products;
          order_products = order_products.map(r => {
            r.return_product_qty_value = "0";
            return r;
          })

          drawers = drawers?.map((element, index) => {
            element.label = element.drawer_name;
            element.value = element.drawer_id;
            element.key = index;
            return element;
          });

          setOpenRefund(true);
          setCurrencies(data.currencies);
          setInvoiceOrderData(data.invoice);
          // setDrawers(data.drawers);
          setDrawersData(drawers);
          setDrawerId(data.drawer_id);
          setCurrencyCode(data.currency_code);
          setOrderProducts(order_products);
          setCashTotal({
            '1_s': 0,
            '2_s': 0,
            '5_s': 0,
            '10_s': 0,
            '20_s': 0,
            '50_s': 0,
            '100_s': 0,
            'cents': 0,
            'nickles': 0,
            'dimes': 0,
            'quarters': 0,
            '50_cents': 0,
            '1_d': 0
          });
        }else{
          enqueueSnackbar(res.data.message, { variant: 'error' })
          setCurrencies([]);
          setInvoiceOrderData(null);
          setDrawers([]);
          setDrawersData([]);
          setDrawerId(null);
          setCurrencyCode('USD');
          setOrderProducts([]);
          setCashTotal({})
        }
      })
    }catch(error){
      console.log('handleRefund error : ',error)
    }
  }
  const handleVoid = (orderId) => {
    setOrderId(orderId)
    setOpenVoid(true)
  }

  const handleAdjust = (orderId,customer_id) => {
    setOrderId(orderId)
    setAdjust(true)
    setPayment(false)
    setDeletePopUpOpen(true)
    setCustomerId(customer_id)
  }
  const handlePayment = (orderId) => {
    setOrderId(orderId)
    setPayment(true)
    setAdjust(false)
    setDeletePopUpOpen(true)
  }
  const confirmDelete = (orderId) => {
    {
      payment &&
        navigate(PATH_DASHBOARD.pov.PaymentProcess, {
          state: { orderId: orderId },
        })
    }
    {
      adjust &&
        // navigate(PATH_DASHBOARD.pov.CheckIn, {
          navigate(`${PATH_DASHBOARD.pov.CheckInEdit}`, {
          state: { orderId: orderId, isEdit: true ,customer_id:customerId},
        })
    }
  }
  const onMultiDelete=()=>{
    setDeleteFull(true)
    const idsToDelete = Object.keys(checked)?.filter(id => checked[id])?.map(id => Number(id)); 
    setTrueIDs(idsToDelete)
  }
 const  handleMultiDeleteProducts=()=>{
  if(trueIDs.length>0){
   axios.post(`${REST_API_END_POINT}point-of-sale/delete-multi-customer-orders`,{
    order_ids:trueIDs
   })
   .then((res)=>{
    if(res.data.status ===1){
      enqueueSnackbar(res.data.msg,{variant:'success'})
      setTrueIDs([])
      setChecked({})
      setDeleteFull(false)
      setMainChecked(false)
      setIndeterminate(false)
    }else{
      enqueueSnackbar(res.data.msg,{variant:'false'})
      setTrueIDs([])
      setChecked({})
      setDeleteFull(false)
      setMainChecked(false)
      setIndeterminate(false)
    }
   })
   .catch((err)=>{
    console.log(err,'errrrr')
   })
  }
 }
  return isLoading ? (
    <LoadingScreen />
  ) : (
    <Page title={user?.facility_name || 'Get Go'}>
      <Container>
        <HeaderBreadcrumbs heading="All Orders" />

        <Card>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Box flexShrink={0}>
              <UserListToolbar
                numSelected={Object.keys(checked).filter(id => checked[id]).length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                item={'products'}
                onDelete={onMultiDelete}
              />
            </Box>
            <Box display="flex" flexGrow={1} flexDirection={{ xs: 'row', sm: 'row' }}
             justifyContent="flex-end"gap={2}  sx={{pr:2,pb:{xs:2,sm:0,}}}>
              {/* <Box display="flex"  flexDirection={{ xs: 'column', sm: 'row' }} 
              justifyContent="space-evenly" gap={2}> */}
              <TextField
                    select
                    fullWidth
                    size='small'
                    label="Order By"              
                     value={filterItem} 
                    onChange={(event) =>{
                      setFilterItem(event.target.value)
                      fetchData(event.target.value)  
                      setPage(0)
                      setRowsPerPage(5)                  
                    }}                    
                    sx={{
                      width: { xs: '100%', sm: '30%' },
                      background: "#fff",
                      marginRight:5,
                      borderRadius: 1,
                      border: 'none',
                      '&:hover': {
                        border: 'none',
                      },
                    }}
                  >
                    {FILTER.map((category) => (
                      <MenuItem
                        key={category.value}
                        value={category.value}
                      >
                        <strong>{category.label}</strong>
                      </MenuItem>
                    ))}
                  </TextField>
                <Button
                sx={{width:{xs:'100%',sm:'fit-content'}}}
                  size="small"
                  variant="contained"
                  onClick={() => cancelOrders()}
                >
                  Cancel Order
                </Button>
              </Box>
            </Box>
          {/* </Box> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800,whiteSpace:'nowrap','&::-webkit-scrollbar': {width: '6px',height: '6px',},'&::-webkit-scrollbar-track': {background: '#f1f1f1',},'&::-webkit-scrollbar-thumb': {background: '#e6e6e6',},'&::-webkit-scrollbar-thumb:hover': {background: '#cccccc',}, }}>
              <Table size='small'>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={orders?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  mainChecked={mainChecked}
                  indeterminate={indeterminate}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
                <TableBody>
                  {filteredUsers
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    ?.map((row) => {
                      const {
                        delivery_date,
                        order_id,
                        type_of_purchase,
                        return_amount,
                        vendor_name,
                        timestamp,
                        refund,
                        status,
                        payment_status,
                        fully_returned,
                        payment_mode,                       
                        order_number,
                        customer_id
                      } = row
                      const isItemSelected = checked[order_id] === true;

                      let deliveryDate = ''
                      if (delivery_date == 0) {
                        deliveryDate = ''
                      } else {
                        deliveryDate = moment(delivery_date * 1000)?.format(
                          'MM/DD/YYYY HH:mm',
                        )
                      }
                      let paymentText = ''
                      if (payment_status == 1 && refund == 0) {
                        paymentText = 'Paid'
                      } else if (row.refund == 1) {
                        paymentText = 'Refunded'
                      } else if(payment_status === 2 ){
                        paymentText = 'Partial Payment'
                      }else {
                        paymentText = 'Unpaid'
                      }
                      let purchaseType = ''
                      if (type_of_purchase == 1) {
                        purchaseType = 'Shop'
                      } else if (type_of_purchase == 2) {
                        purchaseType = 'POS'
                      } else {
                        purchaseType = ''
                      }

                      let payment_status_text = ''
                      if (return_amount != 0 && refund == 0) {
                        payment_status_text = ' Partially Refunded'
                      } else if (refund == 1) {
                        payment_status_text = 'Refunded'
                      } else if (payment_status == 1 && refund == 0) {
                        payment_status_text = 'Paid'
                      } else if(payment_status === 2 ){
                        payment_status_text = 'Partially Paid'
                      } else {
                        payment_status_text = 'Unpaid'
                      }

                      let order_status = CUSTOMER_ORDER_STATUS?.find((r) => {
                        return r?.value == status
                      })
                
                      const validBadgeColors = [
                        'default',
                        'primary',
                        'secondary',
                        'error',
                        'info',
                        'success',
                        'warning',
                      ]
                      const badgeColor = validBadgeColors?.includes(
                        order_status?.badge,
                      )
                        ? order_status?.badge
                        : 'default'
                      let status_text = ''
                      let status_badge = ''
                      if (order_status != null) {
                        status_text = order_status?.label
                        status_badge = (
                          <Badge
                            color={badgeColor}
                            sx={{ whiteSpace: 'break-spaces' }}
                          >
                            {order_status?.label}
                          </Badge>
                        )
                      }

                      return (
                        <TableRow
                          hover
                          key={order_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {status !== 3 && status !== 4 && status !== 5 && (
                              <Checkbox
                                checked={isItemSelected}
                                onChange={() =>                              
                                  handleClick(order_id,page)
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell align='left'>
                            {order_number}
                          </TableCell>
                          <TableCell align="left"> {vendor_name}</TableCell>
                          <TableCell align="left">
                            {moment.unix(timestamp).format('MM/DD/YYYY')}
                          </TableCell>
                          {/* <TableCell align="left">
                            {product_type == 1 ? 'Cannabis' : 'Non-Cannabis'}
                          </TableCell> */}
                          {/* <TableCell align="left">{deliveryDate}</TableCell> */}
                          <TableCell align="left">{payment_status == 0 ? '' : payment_mode}</TableCell>
                          <TableCell align="left">
                            {/* {payment_status_text} */}
                            <Label sx={{ p: '17px',width:'100%',ml:-1 }}variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={payment_status_text === 'Paid' ? 'success' : payment_status_text === 'Unpaid' ? 'error' : payment_status_text === 'Refunded' ? 'info' : 'warning'}>{payment_status_text}</Label>
                          </TableCell>
                          {/* <TableCell align="left">{status_badge}</TableCell> */}
                          <TableCell align="left">
                            <Label sx={{ p: '17px',width:'100%',ml:-1 }}variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={badgeColor}>{status_badge}</Label>
                          </TableCell>
                          <TableCell align="left">
                            {status !==6 && (
                            <OrderMenu
                              userPrevileges={userPrevileges}
                              status={status}
                              payment_mode={payment_mode}
                              payment_status={payment_status}
                              fully_returned={fully_returned}
                              refund={refund}
                              vendor_name={vendor_name}
                              handleApprove={() => handleApprove(order_id)}
                              handleOpenView={() =>{
                                user?.permissions?.some(permission => ['View Order'].includes(permission)) ?         
                                handleview(order_id):
                                enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                              }}
                              handleInvoice={() =>{
                                user?.permissions?.some(permission => ['Order Invoice'].includes(permission)) ?                
                                handleInvoice(order_id):
                                enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                              }}
                              handleEditInvoice={() =>{
                                user?.permissions?.some(permission => ['Edit Invoice'].includes(permission)) ?           
                                handleEditInvoice(order_id) :
                                enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                              }
                              }
                              handleAdjust={() =>{
                                user?.permissions?.some(permission => ['adjust-orders'].includes(permission)) ? 
                                 handleAdjust(order_id,customer_id):
                                 enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                }}
                              handlePrint={() =>{
                                user?.permissions?.some(permission => ['Print Order Labels'].includes(permission)) ?   
                                (
                                  setPaymentStatus(payment_status_text),             
                                 handlePrint(order_id)
                                ):
                                 enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                }}
                              handleComplete={() =>{
                                user?.permissions?.some(permission => ['Complete Order'].includes(permission)) ?                          
                                handleComplete(order_id):
                                enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                              }}
                              handleMakePayment = { ()=>{ handlePayment(order_id); } }
                              handleRefund={() => handleRefund(order_id)}
                              handleVoid={() => handleVoid(order_id)}
                              id={order_id}
                            />
                          )}
                            {/* {payment_status==0&& status>=1&& status!=4&& userPrevileges?.indexOf(132)!==-1 &&
                          <Button variant='contained' style={{width:'140px'}} onClick={()=>{handlePayment(order_id)}}>
                            Make Payment
                          </Button>
                         }  */}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <DeleteConfirmation 
                 isOpen={deleteFull}
                 message={`You want to delete selected Orders `}
                 onDelete={()=>{                            
                 setChecked({})
                 setDeleteFull(false)
                 setTrueIDs([])
                 setMainChecked(false)
                }}
                 onClickConfirm={handleMultiDeleteProducts}
                />
          {/* <Modal
            open={openView}
            modalTitle={'View Order'}
            handleClose={() => setOpenview(false)}
          >
            <ViewOrderDetails
              order_details={viewDetails}
              setOpenview={setOpenview}
              fetchData={fetchData}
            />
          </Modal> */}
          <Modal
            handleClose={() => {
              setOpenInvoice(false)
              setCurrencies([])
            }}
            open={openInvoice}
            modalTitle={invoiceOrderData?.fully_returned == 1 ? 'Refund Invoice' : 'Invoice'}
          >
            <InvoiceView
              setOpenInvoice={setOpenInvoice}
              facility_data={facilityData}
              order_data={invoiceOrderData}
              order_products={orderProduct}
            />
          </Modal>
          <Modal
            handleClose={() => {
              setOpenInvoiceEdit(false)
            }}
            open={openInvoiceEdit}
            modalTitle={'Edit Invoice'}
          >
            <OrderInvoiceEdit
              setOpenInvoiceEdit={setOpenInvoiceEdit}
              invoiceOrderData={invoiceOrderData}
              orderProduct={orderProduct}
              order_id={orderId}
            />
          </Modal>
          <Modal
            handleClose={() => {
              setOpenPrint(false)
            }}
            open={openPrint}
            modalTitle={'Print Order Labels'}
          >
            <PrintOrderLabels
              setOpenPrint={setOpenPrint}
              invoiceOrderData={invoiceOrderData}
              orderProduct={orderProduct}
              currencies={currencies}
              facilityData={facilityData}
              wholeOrderData={wholeOrderData}
              orders={orders}
              handleOpenInvoice={handleOpenInvoice}
              paymentStatus={paymentStatus}
            />
          </Modal>
          <Modal
            handleClose={() => {
              setOpenComplete(false)
            }}
            open={openComplete}
            modalTitle={'Complete Order'}
          >
            <CompleteOrder
              user_id={user?.id}
              facility_id={user?.facility_id}
              order_id={orderId}
              invoiceOrderData={invoiceOrderData}
              setOpenComplete={setOpenComplete}
              refresh={refresh}
              setRefresh={setRefresh}
              // invoiceOrderData={invoiceOrderData}
              // orderProduct={orderProduct}
              // currencies={currencies}
              // facilityData={facilityData}
              // wholeOrderData={wholeOrderData}
            />
          </Modal>
          <Modal width={'1000px'} handleClose={() => { setOpenRefund(false); }}
            open={openRefund}
            modalTitle={'Order Refund'}
          >
            <RefundOrder
              user_id={user?.id}
              facility_id={user?.facility_id}
              order_id={orderId}
              invoiceOrderData={invoiceOrderData}
              setOpenRefund={setOpenRefund}
              refresh={refresh}
              setRefresh={setRefresh}
              currencies={currencies}
              drawers={drawers}
              drawersData={drawersData}
              drawerId={drawerId}
              setDrawerId={setDrawerId}
              currencyCode={currencyCode}
              orderProduct={orderProduct}
              setOrderProducts={setOrderProducts}
              cashTotal={cashTotal}
            />
          </Modal>
          <Modal
            handleClose={() => {
              setOpenVoid(false)
            }}
            open={openVoid}
            modalTitle={'Void Order'}
          >
            <VoidOrder
              refresh={refresh}
              setRefresh={setRefresh}
              user_id={user?.id}
              facility_id={user?.facility_id}
              order_id={orderId}
              setOpenVoid={setOpenVoid}
              invoiceOrderData={invoiceOrderData}
              orderProduct={orderProduct}
              currencies={currencies}
              facilityData={facilityData}
              wholeOrderData={wholeOrderData}
            />
          </Modal>
          <DeleteConfirmationPopup
            open={isDeletePopUpOpen}
            onClose={() => setDeletePopUpOpen(false)}
            onConfirm={() => {
              confirmDelete(orderId)
            }}
            itemContent={
              payment
                ? 'Mark this order as paid?'
                : adjust
                ? 'You want adjust this order?'
                : ''
            }
            itemTitle="Are you sure?"
          />
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Box
          sx={{
            top: 12,
            bottom: 12,
            right: 0,
            position: 'fixed',
            zIndex:99999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...(openView && { right: 12 }),
          }}
        >
          <Paper
            sx={{
              height: '100%', // Set the height to 100% so the scrollbar can appear
              width: openView ? '100%' : '0px',
              maxWidth: 900,
              overflow: 'hidden',
              boxShadow: theme.customShadows.z24,
              transition: theme.transitions.create('width'),
              ...(openView && {
                width: {
                  xs: 350,
                  sm: 550,
                  md: 900,
                },
              }),
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
              <Typography variant="h4">{'View Order'}</Typography>
              <MIconButton onClick={() => setOpenview(false)}>
                <Close />
              </MIconButton>
            </Stack>
            <Divider />
            <Scrollbar style={{ maxHeight: 'calc(100vh - 100px)' }}> {/* Adjust height for mobile */}
              <Stack spacing={4} sx={{ pt: 3, px: 3, pb: 13 }}>
                <ViewOrderDetails
                  order_details={viewDetails}
                  setOpenview={setOpenview}
                  fetchData={fetchData}
                />
              </Stack>
            </Scrollbar>
          </Paper>
        </Box>
      </Container>
    </Page>
  )
}
