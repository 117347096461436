import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Link, Button, Drawer, Typography } from '@material-ui/core';
// hooks
import useAuth from '../../hooks/useAuth';
// routes
import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
import { DocIcon } from '../../assets';
import image from '../../logo/loader-logo.png'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor:
    theme.palette.mode === 'light' ? alpha(theme.palette.primary.main, 0.08) : theme.palette.primary.lighter
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const isInner = pathname === '/dashboard/pov/checkIn';
  const isEditCheckIn = pathname === '/dashboard/pov/checkIn/Edit';
  const isPayment = pathname === '/dashboard/pov/payment-process';
  const { user } = useAuth();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    
    <Scrollbar
      sx={{ height: '100%', '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' } }}
    >
      <Box sx={{ px: 2.5, py: 4 }}>
        <Box
         component={RouterLink} to="/" 
         sx={{ display: 'inline-flex',width:'100%',height:'100%',display:'flex',justifyContent:'center', }}>
          <img src={image} height='74' width='80'/>
        </Box>
      </Box>

      {/* <Box sx={{ mb: 2, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.account}>
          <AccountStyle>
            <MyAvatar />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user.full_name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {user.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box> */}

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt:10 }}>
        <DocStyle>
          <DocIcon sx={{ width: 36, height: 36, mb: 2 }} />
          <Typography gutterBottom variant="subtitle1" sx={{ color: 'grey.800' }}>
            Hi, {user.displafull_nameyName}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: 'grey.600' }}>
            Need help?
            <br /> Please check our docs
          </Typography>
          <Button fullWidth to={PATH_DOCS.root} variant="contained" component={RouterLink}>
            Documentation
          </Button>
        </DocStyle>
      </Box> */}
    </Scrollbar>
  );

  return (
    <>
    {!isInner && !isPayment &&!isEditCheckIn&&(<RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>)}
    </>
  );
}


// // --------------------------------------------------------------------------------------------------------------------------------------
// import PropTypes from 'prop-types';
// import { useState, useEffect } from 'react';
// import { Link as RouterLink, useLocation } from 'react-router-dom';
// // material
// import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
// import { Box, Link, Button, Drawer, Typography, IconButton } from '@material-ui/core';
// import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';
// // hooks
// import useAuth from '../../hooks/useAuth';
// // routes
// import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
// // components
// import Logo from '../../components/Logo';
// import MyAvatar from '../../components/MyAvatar';
// import Scrollbar from '../../components/Scrollbar';
// import NavSection from '../../components/NavSection';
// import { MHidden } from '../../components/@material-extend';
// //
// import sidebarConfig from './SidebarConfig';
// import { DocIcon } from '../../assets';
// import image from '../../logo/image.png'

// // ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280;

// const RootStyle = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('lg')]: {
//     flexShrink: 0,
//     width: DRAWER_WIDTH
//   }
// }));

// const AccountStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   borderRadius: theme.shape.borderRadiusSm,
//   backgroundColor: theme.palette.grey[500_12]
// }));

// const DocStyle = styled('div')(({ theme }) => ({
//   padding: theme.spacing(2.5),
//   borderRadius: theme.shape.borderRadiusMd,
//   backgroundColor:
//     theme.palette.mode === 'light' ? alpha(theme.palette.primary.main, 0.08) : theme.palette.primary.lighter
// }));

// // ----------------------------------------------------------------------

// DashboardSidebar.propTypes = {
//   isOpenSidebar: PropTypes.bool,
//   onCloseSidebar: PropTypes.func
// };

// export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
//   const { pathname } = useLocation();
//   const isInner = pathname === '/dashboard/pov/checkIn';
//   const isPayment = pathname === '/dashboard/pov/payment-process';
//   const { user } = useAuth();
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);

//   useEffect(() => {
//     if (isOpenSidebar) {
//       onCloseSidebar();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [pathname]);

//   const handleToggleSidebar = () => {
//     setIsSidebarOpen((prev) => !prev);
//   };

//   const renderContent = (
//     <Scrollbar
//       sx={{ height: '100%', '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' } }}
//     >
//       <Box sx={{ px: 2.5, py: 4 }}>
//         <Box
//           sx={{ display: 'inline-flex', width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}
//         >
//           <img src={image} height="74" width="80" />
//         </Box>
//       </Box>

//       <NavSection navConfig={sidebarConfig} isSidebarOpen={isSidebarOpen} />

//       <Box sx={{ flexGrow: 1 }} />

//       <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
//         <DocStyle>
//           <DocIcon sx={{ width: 36, height: 36, mb: 2 }} />
//           <Typography gutterBottom variant="subtitle1" sx={{ color: 'grey.800' }}>
//             Hi, {user.full_name}
//           </Typography>
//           <Typography variant="body2" sx={{ mb: 2, color: 'grey.600' }}>
//             Need help?
//             <br /> Please check our docs
//           </Typography>
//           <Button fullWidth to={PATH_DOCS.root} variant="contained" component={RouterLink}>
//             Documentation
//           </Button>
//         </DocStyle>
//       </Box>
//     </Scrollbar>
//   );

//   return (
//     <>
//       {!isInner && !isPayment && (
//         <RootStyle>
//           <MHidden width="lgUp">
//             <Drawer
//               open={isOpenSidebar}
//               onClose={onCloseSidebar}
//               PaperProps={{
//                 sx: { width: DRAWER_WIDTH }
//               }}
//             >
//               {renderContent}
//             </Drawer>
//           </MHidden>

//           <MHidden width="lgDown">
//             <Drawer
//               open
//               variant="persistent"
//               PaperProps={{
//                 sx: { width: isSidebarOpen ? DRAWER_WIDTH : 80, bgcolor: 'background.default' }
//               }}
//             >
//               <IconButton onClick={handleToggleSidebar} sx={{ ml: 'auto', mr: 1, mt: 1 }}>
//                 {isSidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
//               </IconButton>
//               {renderContent}
//             </Drawer>
//           </MHidden>
//         </RootStyle>
//       )}
//     </>
//   );
// }


